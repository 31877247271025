import { SelectedVehicleInfoField } from 'components/car-configurator/components/selected-vehicle-info-field';
import { useAppDispatch } from 'lib/types/redux';
import React from 'react';
import { useSelector } from 'react-redux';
import { carConfiguratorSelector, selectConfiguratorVehicleV2 } from 'redux/modules/carConfigurator/carConfiguratorSlice';

import './selected-vehicle-info.scss';
import { VehicleModelV2 } from 'lib/types';

// TODO - move to CMS
export const VEHICLE_MODELS = {
  Tacoma22: {
    model: 'Tacoma 2022',
    s3FolderSlug: 'tacoma-22',
  },
  Tacoma24: {
    model: 'Tacoma 2024',
    s3FolderSlug: 'tacoma-24',
  },
};

export const SelectedVehicleInfo: React.FC = () => {
  const { displayInfo } = useSelector(carConfiguratorSelector);
  const dispatch = useAppDispatch();

  const onSelectVehicle = (selectedVehicle: VehicleModelV2) => {
    dispatch(selectConfiguratorVehicleV2({
      vehicleModel: selectedVehicle,
    }));
  };

  return (
    <div className="selected-vehicle-info-rct-component">
      <div className='selected-vehicle-info'>
        {displayInfo && Object.entries(displayInfo).map(([key, value]) => (
          <SelectedVehicleInfoField
            key={key}
            value={value}
          />
        ))}
      </div>
      {Object.values(VEHICLE_MODELS).map((vehicleModel) => (
        <button
          key={vehicleModel.model}
          onClick={() => onSelectVehicle(vehicleModel)}
          className='select-vehicle-option'
        >
          {vehicleModel.model}
        </button>
      ))}
    </div>
  );
};


import { SimpleCarConfigurator, AuthRoute } from 'components';
import { CarConfigurator } from 'components/car-configurator';
import { SimpleCarConfiguratorV2 } from 'components/car-configurator-v2';
import { APP_DOMAINS } from 'lib/constants';
import React from 'react';
import { PathRouteProps } from 'react-router-dom';

const NotFoundPage = React.lazy(() => import('../../pages/not-found-page'));
const CustomerHomePage = React.lazy(() => import('../../pages/home-page/customer-home-page'));
const DealerHomePage = React.lazy(() => import('../../pages/home-page/dealer-home-page'));
const InstallerHomePage = React.lazy(() => import('../../pages/home-page/installer-home-page'));
const AuthenticationPage = React.lazy(() => import('../../pages/authentication-page'));
const UserProfilePage = React.lazy(() => import('../../pages/user-profile-page'));
const CarViewerPage = React.lazy(() => import('../../pages/car-viewer/car-viewer-page'));
const CheckoutPage = React.lazy(() => import('../../pages/checkout-page'));
const ManageJobsPage = React.lazy(() => import('../../pages/manage-jobs-page'));
// LAZY_IMPORT_PT (for module generator integration script -- do not remove!)

export interface AppRoutes extends PathRouteProps {
  key: string;
  authRoute?: boolean;
}

const NO_SUBDOMAIN_ROUTE = [
  // ACESSING THE APP WITHOUT A SUBDOMAIN SHOULD TAKE USER TO THE CORP SITE
  {
    key: 'not-found',
    path: '*',
    element: <NotFoundPage />,
  },
];

const COMMON_ROUTES = [
  {
    key: 'not-found',
    path: '*',
    element: <NotFoundPage />,
  },
  {
    key: 'auth',
    path: '/auth/*',
    element: <AuthenticationPage />,
  },
  {
    key: 'checkout',
    path: '/checkout/*',
    element: <CheckoutPage />,
  },
// ADD_ROUTE_PT (for module generator integration script -- do not remove!)
];

const mapProtectedRoutes = (routes) => routes.map((route) => ({
  ...route,
  element: <AuthRoute element={route.element} />,
}));

const COMMON_PROTECTED_ROUTES = [
  ...mapProtectedRoutes([
    {
      key: 'profile',
      path: '/profile/*',
      element: <UserProfilePage />,
    },
  ]),
];

const CUSTOMER_APP_ROUTES: Array<AppRoutes> = [
  ...COMMON_ROUTES,
  ...COMMON_PROTECTED_ROUTES,
  {
    key: 'car-configurator',
    path: '/car-configurator/*',
    element: <CarConfigurator />,
  },
  {
    key: 'simple-car-configurator',
    path: '/simple-car-configurator/*',
    element: <SimpleCarConfigurator />,
  },
  {
    key: 'mod-v2',
    path: '/modv2/*',
    element: <SimpleCarConfiguratorV2 />,
  },
  ...mapProtectedRoutes([
    {
      key: 'main',
      path: '/',
      element: <CustomerHomePage />,
    },
  ]),
];

const DEALER_APP_ROUTES: Array<AppRoutes> = [
  ...COMMON_ROUTES,
  ...COMMON_PROTECTED_ROUTES,
  ...mapProtectedRoutes([
    {
      key: 'main',
      path: '/',
      element: <DealerHomePage />,
    },
    {
      key: 'manage-jobs',
      path: '/manage-jobs',
      element: <ManageJobsPage />,
    },
  ]),
];

const INSTALLER_APP_ROUTES: Array<AppRoutes> = [
  ...COMMON_ROUTES,
  ...COMMON_PROTECTED_ROUTES,
  ...mapProtectedRoutes([
    {
      key: 'main',
      path: '/',
      element: <InstallerHomePage />,
    },
    {
      key: 'manage-jobs',
      path: '/manage-jobs',
      element: <ManageJobsPage />,
    },
  ]),
];

const VIEWER_APP_ROUTES: Array<AppRoutes> = [
  {
    key: 'car-viewer',
    path: '/:id',
    element: <CarViewerPage />,
  },
];

const SUBDOMAIN_ROUTES = {
  [APP_DOMAINS.APP]: CUSTOMER_APP_ROUTES,
  [APP_DOMAINS.DEALER]: DEALER_APP_ROUTES,
  [APP_DOMAINS.INSTALLER]: INSTALLER_APP_ROUTES,
  [APP_DOMAINS.VIEWER]: VIEWER_APP_ROUTES,
};

export const getRoutes = (appSubdomain) => SUBDOMAIN_ROUTES[appSubdomain] || NO_SUBDOMAIN_ROUTE;

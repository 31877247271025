import { useAppDispatch, useAppSelector } from 'lib/types/redux';
import { useEffect, useState } from 'react';
import { usePostVehicleConfigMutation } from 'redux/modules/api';
import { carConfiguratorSelector, setActiveVehicleConfigId, setActiveVehicleConfigPrice } from 'redux/modules/carConfigurator/carConfiguratorSlice';

export const useVehicleConfig = () => {
  const { vehicleConfig } = useAppSelector(carConfiguratorSelector);
  const [createOrFetchVehicleConfig, vehicleConfigResult] = usePostVehicleConfigMutation();
  const [currentConfigPrice, setCurrentConfigPrice] = useState(0);
  const [currentVehicleConfigId, setCurrentVehicleConfigId] = useState('N/A');

  const dispatch = useAppDispatch();

  // Reset the current config price and id if the vehicle config is not valid
  useEffect(() => {
    if (!vehicleConfig || !vehicleConfig.vehicleModel || !vehicleConfig.modConfigs.length) {
      setCurrentConfigPrice(0);
      setCurrentVehicleConfigId('N/A');
      vehicleConfigResult.reset();
    }
  }, [vehicleConfig, vehicleConfigResult]);

  // Create or fetch the current vehicle config
  useEffect(() => {
    if (!vehicleConfig || !vehicleConfig.vehicleModel || !vehicleConfig.modConfigs.length) {
      return;
    }

    createOrFetchVehicleConfig(vehicleConfig);
  }, [createOrFetchVehicleConfig, vehicleConfig]);

  useEffect(() => {
    dispatch(setActiveVehicleConfigId(vehicleConfigResult.data?.id));
    dispatch(setActiveVehicleConfigPrice(vehicleConfigResult.data?.defaultPrice));
  }, [dispatch, vehicleConfigResult.data?.id, vehicleConfigResult.data?.defaultPrice]);

  // If available, set the current config price and id
  useEffect(() => {
    if (!vehicleConfigResult.data) {
      return;
    }

    setCurrentConfigPrice(vehicleConfigResult.data?.defaultPrice);
    setCurrentVehicleConfigId(vehicleConfigResult.data?.id);
  }, [vehicleConfigResult.data]);

  return {
    localVehicleConfig: vehicleConfig,
    vehicleConfigId: currentVehicleConfigId,
    currentConfigPrice,
    isValidConfigIdSelected: !Number.isNaN(parseInt(currentVehicleConfigId, 10)),
    vehicleConfigResultData: vehicleConfigResult.data,
  };
};

import { useEffect, useState } from 'react';
import {
  ListObjectsCommand,
  S3Client,
} from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { useSelector } from 'react-redux';
import { carConfiguratorSelector, initializeCarConfiguratorV2 } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { useAppDispatch } from 'lib/types/redux';
import { VEHICLE_MODELS } from '../components/selected-vehicle-info/selected-vehicle-info';

const assetBaseUrl = 'https://assets.modcars-dev.com/';

interface CarouselImage {
  key: string;
  url: string;
}

interface UseCarouselImagesReturnType {
  carouselImages: CarouselImage[]
}

const DEFAULT_VEHICLE_MODEL = VEHICLE_MODELS.Tacoma22;

export const useCarouselImages: () => UseCarouselImagesReturnType = () => {
  const [carouselImages, setCarouselImages] = useState<CarouselImage[]>([]);
  const { vehicleModelDataV2 } = useSelector(carConfiguratorSelector);
  const dispatch = useAppDispatch();

  const fetchVehicleCarouselImages = (s3FolderSlug: string) => {
    const client = new S3Client({
      region: 'us-east-1',
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: 'us-east-1' },
        identityPoolId: 'us-east-1:8b8b61d7-30f8-4e12-b26c-008d317004e9',
      }),
    });

    const command = new ListObjectsCommand({
      Bucket: 'mod-dev-global-assets',
      Prefix: `mod_v2/${s3FolderSlug}/wraps/colors/dark-green/carousel`,
    });
    client.send(command).then(({ Contents }) => {
      const matchingObjects = [];
      const sortedObjects = Contents.sort((a, b) => {
        const aImageNumber = parseInt(a.Key.split('.')[1].split('.')[0], 10);
        const bImageNumber = parseInt(b.Key.split('.')[1].split('.')[0], 10);
        if (aImageNumber < bImageNumber) {
          return -1;
        }
        if (aImageNumber > bImageNumber) {
          return 1;
        }
        return 0;
      });

      sortedObjects.forEach((object) => {
        if (object.Key.endsWith('.jpg') || object.Key.endsWith('.png')) {
          matchingObjects.push({
            key: object.Key,
            url: `${assetBaseUrl}${object.Key}`,
          });
        }
      });

      setCarouselImages(matchingObjects);
    },
    );
  };

  useEffect(() => {
    if (!vehicleModelDataV2) {
      dispatch(initializeCarConfiguratorV2({
        defaultVehicle: DEFAULT_VEHICLE_MODEL,
      }));
      return;
    }

    fetchVehicleCarouselImages(vehicleModelDataV2.s3FolderSlug);
  }, [vehicleModelDataV2, dispatch]);

  return {
    carouselImages,
  };
};

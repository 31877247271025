import { combineReducers } from '@reduxjs/toolkit';
import vehiclePicker from './vehiclePickerSlice/vehiclePickerSlice';
import forgotPassword from './forgotPassword/forgotPasswordSlice';
import login from './login/loginSlice';
import profile from './profile/profileSlice';
import signUp from './signUp/signUpSlice';
import resetPassword from './resetPassword/resetPasswordSlice';
import order from './order/orderSlice';

const combinedReducers = combineReducers({
  login,
  forgotPassword,
  signUp,
  profile,
  vehiclePicker,
  resetPassword,
  order,
});

export default combinedReducers;

export type BaseFormState = typeof combinedReducers;


export const {
  loginFormState,
  forgotPasswordFormState,
  signUpFormState,
  profileFormState,
  vehiclePickerFormState,
  resetPasswordFormState,
  orderFormState,
} = {
  loginFormState: state => state.forms.login,
  forgotPasswordFormState: state => state.forms.forgotPassword,
  signUpFormState: state => state.forms.signUp,
  profileFormState: state => state.forms.profile,
  vehiclePickerFormState: state => state.forms.vehiclePicker,
  resetPasswordFormState: state => state.forms.resetPassword,
  orderFormState: state => state.forms.order,
};
